<template>
  <section class="blog_list container mb-4">
    <div class="row">
      <div class="col-lg-4 my-2" v-for="(blog, index) in popularPosts" :key="index">
        <img class="mb-2 sp_rimg" :src="blog.image_url" alt="" />
        <div class="blog_title mt-2 mb-2">
          <a :href="'/' + contentType + '/' + blog.alias">{{ blog.title }}</a>
        </div>
        <div class="blog_text">
          <p v-html="truncateTitle(blog.fulltext, 100)"></p>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>
export default {
  props: ["popularPosts", "contentType"],
  methods: {
    truncateTitle(title, limit) {
      return title.length > limit ? title.substring(0, limit) + "..." : title;
    },
  },
};
</script>
