import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap";
import "../src/assets/css/style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import '@fortawesome/fontawesome-free/css/all.min.css';


// Import specific icons
import { faUser,faStar } from "@fortawesome/free-solid-svg-icons";

// Add the imported icons to the library
library.add(faUser,faStar);
import router from "./router";
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";

const app = createApp(App);
const pinia = createPinia();
let sid = "autofybranding"; 
let baseUrl = "https://autofybranding.com/";
// http://localhost:9100/fdrives/sid/techecosys/website/source/
// fron end 
// let apijson_path = "/apijson/assets/settings.json";

// local server 
// let apijson_path = "http://localhost:9100/fdrives/sid/"+sid+"/website/source/apijson/assets/settings.json";

//live server 
let apijson_path = baseUrl+ "fdrives/sid/"+sid+"/website/source/apijson/assets/settings.json";

// console.log(publicPath); 
app.config.globalProperties.$apijsonPath = apijson_path;
app.config.globalProperties.$apiBaseUrl = baseUrl;
app.config.globalProperties.$apiAccessKey = "123456789";
app.component("font-awesome-icon", FontAwesomeIcon);

const head = createHead();

app.use(router).use(pinia).use(head).mount("#app");
