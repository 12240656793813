<template>
  <section class="banner_area py-5" v-if="!sliderStore.loading">
    <div class="container">
      <div class="row">
        <!-- Textbox Section -->
        <div
          class="col-lg-4 col-md-5 col-12 textbox p-lg-5 p-md-4 p-3 text-center text-md-start order-2 order-lg-1"
        >
          <h3 class="fs-5 fs-md-4">Let’s Create a Brand you’ll be proud to have.</h3>
          <p class="pb-3 b_text">
            "Create a clean, memorable logo that reflects the brand's identity,
            using simple shapes, balanced colors, and versatile typography."
          </p>
          <div class="justify-content-center gap-2">
            <a href="/portfolio" class="tbtn all_btn bg-white">SEE ALL PROJECTS</a>
            <a href="/pricing" class="all_btn">PRICING</a>
          </div>
        </div>

        <!-- Swiper Slider Section -->
        <div class="col-lg-8 col-md-7 col-12 slide-box mt-4 mt-md-0 order-1 order-lg-2">
          <swiper
            :slides-per-view="1"
            :loop="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            effect="fade"
            :fade-effect="{ crossFade: true }"
            :virtual-translate="true"
            :speed="500"
            class="mySwiper"
            :modules="modules"
          >
            <swiper-slide v-for="(slide, index) in sliderStore.slides" :key="index">
              <img :src="slide.image" :alt="slide.title" class="img-fluid w-100" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade, Virtual } from "swiper/modules";
import { useAppStore } from "@/stores/appStore";

export default {
  name: "BannerSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const sliderStore = useAppStore();
    return {
      sliderStore,
      modules: [Autoplay, EffectFade, Virtual],
    };
  },
};
</script>
