<template>
  <!--Price-->
  <section class="package py-5" id="pricing">
    <div class="container">
      <div class="text-center">
        <div class="section_title">Pick the right Package for you</div>
        <div class="section_text">
          Pick between Bronze, Gold, and Platinum level<br />
          Packages based on your needs
        </div>
      </div>
      <!--tab section-->

      <nav class="pt-4 nav_list">
        <div
          class="nav nav-pills justify-content-center price_tabs"
          id="nav-tab"
          role="tablist"
        >
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Logo
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Business Card
          </button>
          <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            SMM
          </button>
          <button
            class="nav-link"
            id="nav-disabled-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-disabled"
            type="button"
            role="tab"
            aria-controls="nav-disabled"
            aria-selected="false"
          >
            Website
          </button>
        </div>
      </nav>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 my-2 middle">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a href="pricing" class="get_started_btn bg-white text-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i> FREE High-Quality Color Format
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
          tabindex="0"
        >
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 my-2 middle">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a href="pricing" class="get_started_btn bg-white text-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i> FREE High-Quality Color Format
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
          tabindex="0"
        >
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 my-2 middle">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a href="pricing" class="get_started_btn bg-white text-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i> FREE High-Quality Color Format
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-disabled"
          role="tabpanel"
          aria-labelledby="nav-disabled-tab"
          tabindex="0"
        >
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 my-2 middle">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>FREE High-Quality Color Format</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a href="pricing" class="get_started_btn bg-white text-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 my-2">
              <div class="price_card">
                <h2 class="price_title">Business Card</h2>
                <p class="price_subtitle">
                  Business Card Design Package - Gold
                </p>
                <div class="price_amount">
                  <span class="currency">৳</span>
                  <span class="amount">6999</span>
                  <span class="period">/month</span>
                </div>

                <ul class="price_features">
                  <li class="included">
                    <i class="fas fa-check"></i> Delivery Time: 1 Days
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Revisions: 5
                  </li>
                  <li class="included">
                    <i class="fas fa-check"></i> Number of Initial Concepts: 3
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Money Back Guarantee</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i> FREE High-Quality Color Format
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                  <li class="not-included">
                    <i class="fas fa-times"></i>
                    <span>Voluptate id voluptas qui sed aperiam rerum</span>
                  </li>
                </ul>

                <div class="text-center pt-5 pb-2">
                  <a
                    href="pricing"
                    class="get_started_btn border border-warning"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Price End-->
</template>

<script>
export default {
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
