<template>
  <PageTitle :title="contentType" :type="contentType" />
  <div class="container text-center section_title">
    <h2>This is portfolio section</h2>
  </div>
  <section class="portfolio py-5 container" id="portfolio">
    <div class="text-center">
      <div class="section_title">Portfolio</div>
      <div class="section_text">
        Pick between Bronze, Gold, and Platinum level<br />
        Packages based on your needs
      </div>
    </div>

    <!-- Tab Navigation -->
    <nav class="pt-4 nav_list">
      <div
        class="nav nav-pills justify-content-center price_tabs"
        role="tablist"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['nav-link', { active: activeTab === tab.id }]"
          @click="activeTab = tab.id"
          type="button"
          role="tab"
          :aria-controls="`nav-${tab.id}`"
          :aria-selected="activeTab === tab.id"
        >
          {{ tab.label }}
        </button>
      </div>
    </nav>

    <!-- Tab Content -->
    <div class="tab-content pt-4">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :id="`nav-${tab.id}`"
        class="tab-pane fade"
        :class="{ 'show active': activeTab === tab.id }"
        role="tabpanel"
        :aria-labelledby="`nav-${tab.id}-tab`"
        tabindex="0"
      >
        <!-- Images for each Tab -->
        <div class="row">
          <div
            class="col-lg-4 col-md-6 my-2"
            v-for="(image, imgIndex) in tab.images"
            :key="imgIndex"
          >
            <div class="mb-2">
              <div class="image_container">
                <img
                  class="img-fluid"
                  :src="image"
                  alt="Package Image"
                />
                <!-- Eye icon overlay -->
                <div class="eye_icon">
                  <i class="fas fa-eye"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="col-lg-12 col-12 m-4">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center mb-0">
          <!-- Previous Button -->
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="#"
              aria-label="Previous"
              @click.prevent="changePage(currentPage - 1)"
            >
              <span aria-hidden="true">&lt;</span>
            </a>
          </li>

          <!-- Page Numbers -->
          <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <a
              :style="currentPage === page ? activeStyle : inactiveStyle"
              class="page-link rpage"
              href="#"
              @click.prevent="changePage(page)"
            >
              {{ page }}
            </a>
          </li>

          <!-- Next Button -->
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage + 1)"
            >
              <span aria-hidden="true ">&gt;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";

export default {
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      content: null,
      error: null,
      popularPosts: [],
      activeTab: "logo",
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 4,
      activeStyle: {
        backgroundColor: "#ffc107", // Background color for active page
        color: "#fff", // Font color for active page
      },
      inactiveStyle: {
        backgroundColor: "#fff", // Background color for inactive pages
        color: "#ffc107", // Font color for inactive pages
      },
      tabs: [
        {
          id: "logo",
          label: "Logo",
          images: [
            "https://autofybranding.com/fdrives/sid/autofybranding/component6_s53156.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component7_s06914.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component8_s95986.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component3_s89122.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component4_s75651.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component5_s16904.png",
          ],
        },
        {
          id: "business",
          label: "Business Card",
          images: [
            "https://autofybranding.com/fdrives/sid/autofybranding/component6_s53156.png",
            "component5.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component3_s89122.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component4_s75651.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component6_s53156.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component5_s16904.png",
          ],
        },
      ],
    };
  },
  created() {
    this.fetchSinglePage();
    this.fetchContentList();
  },

  methods: {
    async fetchSinglePage() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/content/${this.alias}?access_key=${this.$apiAccessKey}&debug=1`
        );
        const data = await response.json();
        if (response.ok) {
          this.content = data.content;
          // For meta tag
          useHead({
            title: this.content.meta_title || this.content.title,
            meta: [
              {
                name: "description",
                content: this.content.meta_description || "",
              },
              {
                name: "keywords",
                content: this.content.meta_keywords || "",
              },
            ],
          });
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} details`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },
    async fetchContentList() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/contents/${this.contentType}?access_key=${this.$apiAccessKey}&debug=1`
        );
        const data = await response.json();
        if (response.ok) {
          this.popularPosts = data.contents;
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} list`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },

    formatDate(dateStr) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateStr).toLocaleDateString(undefined, options);
    },
  },
};
</script>
