import HomePage from "@/components/Home/HomePage.vue";
import BlogPage from "@/views/BlogPage.vue";
import ContactUs from "@/views/ContactUs.vue";
import PortfolioPage from "@/views/PortfolioPage.vue";
import PricingPage from "@/views/PricingPage.vue";
import SinglePage from "@/views/SinglePage.vue";
import WebsiteContents from "@/views/WebsiteContents.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: HomePage,
    props: true,
  },
  {
    path: "/contact-us",
    component: ContactUs,
    props: {
      contentType: "contact-us", 
    },
  },
  {
    path: "/blog",
    component: BlogPage,
    props: {
      contentType: "blog", 
    },
  },
  {
    path: "/pricing",
    component: PricingPage,
    props: {
      contentType: "price", 
    },
  },
  {
    path: "/portfolio",
    component: PortfolioPage,
    props: {
      contentType: "post", 
    },
  },
  {
    path: "/:contentType",
    name: "ContentList",
    component: WebsiteContents,
    props: (route) => ({
      contentType: route.params.contentType,
    }),
  },
  {
    path: "/:contentType/:alias",
    name: "SinglePage",
    component: SinglePage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
