<template>
  <PageTitle :title="contentType" :type="contentType" />

  <div class="container p-5 mt-2 bg-white wc_main">
    <div class="row">
      <div class="col-lg-8">
        <div class="row align-items-start">
          <!-- Content Section -->
          <div class="my-4" v-for="item in items" :key="item.id">
            <div class="">
              <!-- Image Section with rounded corners -->
              <div class="wc-list_page_img mb-1">
                <img
                  :src="item.image_url"
                  :alt="item.title"
                  :title="item.title"
                  class="img-fluid"
                  style="border-radius: 20px; width: 100%"
                />
              </div>
              <!-- Content Section -->
              <div class="p-1">
                <!-- Title Section -->
                <div class="wc-list_page_title py-2">
                  <a
                    :href="'/' + contentType + '/' + item.alias"
                    class="font-weight-bold"
                  >
                    <h1>{{ item.title }}</h1>
                  </a>
                </div>

                <!-- Full Text Section -->
                <div class="wc-list_page_deatils mt-3">
                  <p>{{ item.fulltext }}</p>
                </div>
                <!-- Read More Button -->
                <div class="text-right">
                  <router-link
                    :to="{
                      name: 'SinglePage',
                      params: { alias: item.alias, contentType: contentType },
                    }"
                    class="btn btn-main btn-icon btn-round-full all_btn"
                  >
                    Read More <i class="icofont-simple-right ml-2"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination Controls -->
          <div class="col-lg-12 col-12 m-4">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center mb-0">
                <!-- Previous Button -->
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(currentPage - 1)"
                  >
                    <span aria-hidden="true">&lt;</span>
                  </a>
                </li>

                <!-- Page Numbers -->
                <li
                  class="page-item"
                  v-for="page in totalPages"
                  :key="page"
                  :class="{ active: currentPage === page }"
                >
                  <a
                    :style="currentPage === page ? activeStyle : inactiveStyle"
                    class="page-link rpage"
                    href="#"
                    @click.prevent="changePage(page)"
                  >
                    {{ page }}
                  </a>
                </li>

                <!-- Next Button -->
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(currentPage + 1)"
                  >
                    <span aria-hidden="true ">&gt;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!-- Sidebar -->
      <SideBar :popularPosts="items" :contentType="contentType" />
    </div>
  </div>
</template>
<script>
import PageTitle from "./PageTitle.vue";
import SideBar from "./SideBar.vue";

export default {
  name: "WebsiteContents",
  components: {
    PageTitle,
    SideBar,
  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 4,
      activeStyle: {
        backgroundColor: "#ffc107", // Background color for active page
        color: "#fff", // Font color for active page
      },
      inactiveStyle: {
        backgroundColor: "#fff", // Background color for inactive pages
        color: "#ffc107", // Font color for inactive pages
      },
    };
  },
  methods: {
    async fetchContent(page = 1) {
      try {
        let url =
          this.$apiBaseUrl +
          `website/website_api/contents/${this.contentType}?access_key=
          ${this.$apiAccessKey}&page=${page}&limit=${this.itemsPerPage}`;

        const response = await fetch(url);
        if (!response.ok)
          throw new Error(
            `Error fetching ${this.contentType}: ${response.status}`
          );

        const result = await response.json();
        this.items = result.contents;
        const paging = result.paging;

        this.totalPages = paging.pages;
        this.itemsPerPage = paging.per_page;
      } catch (error) {
        console.error(`Error fetching ${this.contentType}:`, error);
      }
    },

    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchContent(this.currentPage);
      }
    },
  },
  mounted() {
    this.fetchContent();
  },
};
</script>
