// stores/appStore.js
import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    analyticCode: "",
    navItems:[],
    slides: [],
    sidSite: null,
    homePage: null,
    jsonVersion: 20241125,
    loading: true,
  }),
  actions: {
    async fetchData(apijsonPath) {
      try {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const debug = params.get("debug");

        if (debug) {
          this.jsonVersion = Math.random().toString(16).slice(2);
        }

        const response = await fetch(
          `${apijsonPath}?v=${this.jsonVersion}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        this.navItems = data?.widgets?.menu?.main_menu || [];
        this.homePage = data?.widgets?.home_page_block;
        this.sidSite = data?.sid_site?.app_setting?.organization_information;
        this.analyticCode = data?.sid_site?.analytic_code;


        const slider = data?.widgets?.slider["0"];
        if (slider?.items) {
          this.slides = slider.items.map((item) => ({
            image: this.makeAbsoluteUrl(item.image),
            title: item.title,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    makeAbsoluteUrl(relativeUrl) {
      const baseUrl = "https://autofybranding.com/";
      return relativeUrl.startsWith("http") ? relativeUrl : `${baseUrl}${relativeUrl}`;
    },
  },
});
