<template>
  <div v-if="content" class="single">
    <PageTitle :type="contentType" :alias="alias" :ShowAlias="true" />
    <div :class="{ container: isContainer, 'full-width': isFullWidth }">
      <!-- {{ content }} -->
      <div class="col-lg-12 sp_title">
        {{ content.title }}
      </div>

      <div class="col-lg-8">
        <div class="sp_data text-muted">
          <ul class="list-unstyled d-flex">
            <li class="sp_dt d-flex p-3">
              <div class="sp_icon">
                <i class="fas fa-user"></i>
              </div>
              <p>
                Written By <br />
                Admin
              </p>
            </li>
            <li class="sp_dt d-flex p-3">
              <div class="sp_icon">
                <i class="fas fa-calendar-alt"></i>
              </div>
              <p>
                Published on <br />
                {{ formatDate(content.publish_date) }}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <!-- Main Content -->
        <div class="col-lg-8">
          <!-- Featured Image -->

          <div v-if="content?.is_featured_image" class="mb-4">
            <img
              :src="content.image_url"
              :alt="content.title"
              :title="content.title"
              class="limg"
            />
          </div>

          <!-- Full Text Content -->

          <div v-html="content.fulltext" class=" mb-5"></div>
        </div>

        <!-- Sidebar -->
        <SideBar :popularPosts="popularPosts" :contentType="contentType" />
      </div>
      <div class="row">
        <p class="section_title">You might like</p>
        <RelatedTopics
          :popularPosts="popularPosts"
          :contentType="contentType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";
import SideBar from "./SideBar.vue";
import RelatedTopics from "./RelatedTopics.vue";

export default {
  props: ["alias", "contentType"],
  components: {
    PageTitle,
    SideBar,
    RelatedTopics,
  },
  data() {
    return {
      content: null,
      error: null,
      popularPosts: [],
    };
  },
  computed: {
    isContainer() {
      return this.content.page_template_column !== "full_width";
    },
    isFullWidth() {
      return this.content.page_template_column === "full_width";
    },
    mainContentClass() {
      switch (this.content.page_template_column) {
        case "one_column":
          return "col-lg-12";
        case "two_column_leftbar":
          return "col-lg-8";
        case "two_column_rightbar":
          return "col-lg-8";
        default:
          return "col-lg-12";
      }
    },
    hasSidebar() {
      return ["two_column_leftbar", "two_column_rightbar"].includes(
        this.content.page_template_column
      );
    },
    sidebarClass() {
      return this.content.page_template_column === "two_column_leftbar"
        ? "col-lg-4 order-lg-1"
        : "col-lg-4 order-lg-2";
    },
  },
  created() {
    this.fetchSinglePage();
    this.fetchContentList();
  },
  watch: {
    alias(newAlias, oldAlias) {
      if (newAlias !== oldAlias) {
        this.fetchSinglePage();
      }
    },
  },
  methods: {
    async fetchSinglePage() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/content/${this.alias}?access_key=${this.$apiAccessKey}`
        );
        const data = await response.json();
        if (response.ok) {
          this.content = data.content;
          // For meta tag
          useHead({
            title: this.content.meta_title || this.content.title,
            meta: [
              {
                name: "description",
                content: this.content.meta_description || "",
              },
              {
                name: "keywords",
                content: this.content.meta_keywords || "",
              },
            ],
          });
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} details`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },
    async fetchContentList() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/contents/${this.contentType}?access_key=${this.$apiAccessKey}&debug=1&limit=3`
        );
        const data = await response.json();
        if (response.ok) {
          this.popularPosts = data.contents;
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} list`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },

    formatDate(dateStr) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateStr).toLocaleDateString(undefined, options);
    },
  },
};
</script>
