<template>
  <div>
    <NavBar />
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import { useAppStore } from "@/stores/appStore";
import FooterSection from "./components/Shared/FooterSection.vue";
import NavBar from "./components/Shared/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterSection,
  },
  methods: {
    insertAnalyticalCode(code) {
      if (!code) return;

      try {
        document.head.insertAdjacentHTML("beforeend", code);
      } catch (error) {
        console.error("Error inserting analytical code:", error);
      }
    },
  },
  async created() {
    const appStore = useAppStore();
    await appStore.fetchData(this.$apijsonPath);
    if (appStore.analyticCode) {
      this.insertAnalyticalCode(appStore.analyticCode);
    }
  },
};
</script>
