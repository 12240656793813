<template>
  <!--About Section-->
  <section class="about py-5">
    <div class="container">
      <div class="row">
        <h1 class="section_title">About Autofy Branding</h1>
        <p class="section_text">
          Unlock your business’s potential with Autofy Branding
        </p>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-12 mb-4">
            <img
              class="img-fluid"
              :src="`https://autofybranding.com/${blockData.items[0].image}`"
              alt="alt"
            />
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="about_content">
              <div class="about_text">
                <p>{{ blockData.items[0].fulltext }}</p>
              </div>
              <div class="about_button">
                <a class="btn all_btn" href="/pages/about-autofy-branding"
                  >Start Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AboutSection",
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
