<template>
  <section class="bottom_area mt-5">
    <div class="bottom_area_one">
      <img src="../../assets/image/footerimg.png" class="img-fluid" alt="" />
      <div class="ltalkht">
        <h2 class="talk">Let's talk</h2>
      </div>
    </div>
    <footer class="border-top">
      <div class="container d-flex flex-wrap justify-content-between align-items-center p-4">
        <p class="col-md-4 mb-0 text-muted text-white">
          2024 autofybranding <br />
          All Rights Reserved.
        </p>

        <ul class="nav col-md-4 justify-content-end">
          <li class="nav-item">
            <a href="/pages/about-autofy-branding" class="nav-link px-2 text-muted">About</a>
          </li>
          <li class="nav-item">
            <a href="/pricing" class="nav-link px-2 text-muted">Pricing</a>
          </li>
          <li class="nav-item">
            <a href="/portfolio" class="nav-link px-2 text-muted">Portfolio</a>
          </li>
          <li class="nav-item">
            <a href="/blog" class="nav-link px-2 text-muted">Blog</a>
          </li>
        </ul>
      </div>
    </footer>
  </section>
</template>

<script>
import { useAppStore } from "@/stores/appStore";

export default {
  computed: {
    sidSite() {
      const appStore = useAppStore();
      return appStore.sidSite;
    },
  },
  metaInfo() {
    const sidSite = this.sidSite;

    return {
      title: sidSite?.["meta-title"] || "Default Title", // Set the title
      meta: [
        { name: "description", content: sidSite?.["meta-description"] || "Default Description" }, // Set the description
        { name: "keywords", content: sidSite?.["meta-keyword"] || "Default Keywords" }, // Set the keywords
      ],
    };
  },
};
</script>
