<template>
  <PageTitle
    :title="contentType" :type="contentType"
  />
  <div class="container text-center section_title">
    <h2>This is price section</h2>
  </div>

</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";


export default {
  props: ["alias", "contentType"],
  components: {
    PageTitle,
    
  },
  data() {
    return {
      content: null,
      error: null,
      popularPosts: [],
    };
  },
  created() {
    this.fetchSinglePage();
    this.fetchContentList();
  },

  methods: {
    async fetchSinglePage() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/content/${this.alias}?access_key=${this.$apiAccessKey}&debug=1`
        );
        const data = await response.json();
        if (response.ok) {
          this.content = data.content;
          // For meta tag
          useHead({
            title: this.content.meta_title || this.content.title,
            meta: [
              {
                name: "description",
                content: this.content.meta_description || "",
              },
              {
                name: "keywords",
                content: this.content.meta_keywords || "",
              },
            ],
          });
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} details`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },
    async fetchContentList() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/contents/${this.contentType}?access_key=${this.$apiAccessKey}&debug=1`
        );
        const data = await response.json();
        if (response.ok) {
          this.popularPosts = data.contents;
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} list`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },

    formatDate(dateStr) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateStr).toLocaleDateString(undefined, options);
    },
  },
};
</script>
