<template>
  <section class="container mt-5">
    <div class="page_title mb-2">
      <div>
        <div class="row">
          <div class="col-md-12 pt-2">
            <!-- Ensure title exists before trying to capitalize it -->
            <!-- <h1 style="font-weight: 700; font-size: 1.75rem">
              {{ title ? title[0].toUpperCase() + title.slice(1) : "" }}
            </h1> -->
            <ul class="breadcrumb">
              <li class="highlight">
                <a href="/">Home </a>
                <span class="divider m-2">&gt;</span>
              </li>
              <li :class="{ highlight: ShowAlias }">
                <a :href="`/${type}`">{{ type }}</a>
                <span class="divider m-2">&gt;</span>
              </li>
              <li v-if="ShowAlias && alias">
                {{ alias }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    alias: {
      type: String,
      default: null,
    },
    ShowAlias: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
