<template>
  <PageTitle :title="contentType" :type="contentType"/>
  <section class="blog_list container">
    <div class="row">
      <div class="col-lg-4 my-2" v-for="(blog, index) in blogList" :key="index">
        <img class="mb-2" :src="blog.image_url" alt="" />
        <div class="blog_title mt-2 mb-2">
          <a  :href="'/' + contentType + '/' + blog.alias">
          <h1>{{ blog.title }}</h1></a>
        </div>
        <div class="blog_text">
          <p v-html="truncateText(blog.fulltext, 100)"></p>
        </div>
      </div>
      <!-- Pagination Controls -->
      <div class="col-lg-12 col-12 m-4">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center mb-0">
            <!-- Previous Button -->
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a
                class="page-link"
                href="#"
                aria-label="Previous"
                @click.prevent="changePage(currentPage - 1)"
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>

            <!-- Page Numbers -->
            <li
              class="page-item"
              v-for="page in totalPages"
              :key="page"
              :class="{ active: currentPage === page }"
            >
              <a
                :style="currentPage === page ? activeStyle : inactiveStyle"
                class="page-link rpage"
                href="#"
                @click.prevent="changePage(page)"
              >
                {{ page }}
              </a>
            </li>

            <!-- Next Button -->
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="changePage(currentPage + 1)"
              >
                <span aria-hidden="true ">&gt;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";

export default {
  name: "BlogPage",
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      blogList: [],
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 6,
      activeStyle: {
        backgroundColor: "#ffc107", // Background color for active page
        color: "#fff", // Font color for active page
      },
      inactiveStyle: {
        backgroundColor: "transparent", // Background color for inactive pages
        color: "#fff", // Font color for inactive pages
      },
    };
  },
  methods: {
    async fetchContent(page = 1) {
      try {
        let url =
          this.$apiBaseUrl +
          `website/website_api/contents/blog?access_key=
          ${this.$apiAccessKey}&page=${page}&limit=${this.itemsPerPage}`;

        const response = await fetch(url);
        if (!response.ok)
          throw new Error(
            `Error fetching ${this.contentType}: ${response.status}`
          );

        const result = await response.json();
        this.blogList = result.contents;
        // console.log("blogs", this.blogList);
        const paging = result.paging;

        this.totalPages = paging.pages;
        // console.log("totalpages", this.totalPages);
        this.itemsPerPage = paging.per_page;
        // For meta tag
        useHead({
          title: this.content.meta_title || this.content.title,
          meta: [
            {
              name: "description",
              content: this.content.meta_description || "",
            },
            {
              name: "keywords",
              content: this.content.meta_keywords || "",
            },
          ],
        });
      } catch (error) {
        console.error(`Error fetching ${this.contentType}:`, error);
      }
    },
    truncateText(text, length) {
      return text.length > length ? `${text.slice(0, length)}....` : text;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchContent(this.currentPage);
      }
    },
  },
  async mounted() {
    await this.fetchContent();
  },
};
</script>
