<template>
  <PageTitle :type="contentType" />
  <section class="contact_us text-white">
    <div class="container">
      <div class="pt-2">
        <p>
          <strong>Address</strong><br />{{ contactContent.address
          }}<br /><br /><strong>Telephone</strong><br />{{
            contactContent.mobile
          }}, <br /><br /><strong>Mobile (Hotline)</strong><br />{{
            contactContent["hot-number"]
          }}<br /><br /><strong>Email</strong><br />{{
            contactContent["contact-email"]
          }}
        </p>
      </div>
      <div class="card p-3 contact_form">
        <div class="alert alert-success" v-if="isSubmitted">
          <h2 class="text-success">Successfully submitted !</h2>
        </div>
        <div v-else>
          <form
            @submit.prevent="handleSubmit"
            class="row g-3 needs-validation"
            novalidate
          >
            <div class="col-md-6">
              <label for="validationCustom01" class="form-label"
                >First name</label
              >
              <input
                v-model="form.first_name"
                required
                type="text"
                class="form-control"
                id="first_name"
                placeholder="Your First Name"
              />
            </div>
            <div class="col-md-6">
              <label for="validationCustom02" class="form-label"
                >Last name</label
              >
              <input
                v-model="form.last_name"
                required
                type="text"
                class="form-control"
                id="last_name"
                placeholder="Your Last Name"
              />
            </div>
            <div class="col-md-6">
              <label for="validationCustomUsername" class="form-label"
                >Mobile Number</label
              >
              <input
                v-model="form.phone"
                required
                type="text"
                class="form-control"
                id="phone"
                placeholder="Mobile"
              />
            </div>
            <div class="col-md-6">
              <label for="validationCustom03" class="form-label">Email</label>
              <input
                v-model="form.email"
                required
                type="email"
                class="form-control"
                id="email"
                placeholder="Your Email"
              />
            </div>
            <div class="col-md-6">
              <label for="validationCustom04" class="form-label"
                >Feedback Type</label
              >
              <select
                v-model="form.feedback_type_id"
                required
                class="form-select"
              >
                <option disabled value="">Select Feedback Type</option>
                <option value="1">General Query</option>
                <option value="2">Appointment</option>
              </select>
            </div>
            <div class="col-md-12">
              <input
                v-model="form.send_copy_to_yourself"
                type="checkbox"
                id="send_copy_to_yourself"
              />
              <label class="m-2" for="send_copy_to_yourself">
                Send copy to yourself
              </label>
            </div>
            <div class="col-12">
              <label for="validationCustomUsername" class="form-label"
                >Your Message</label
              >
              <textarea
                v-model="form.message"
                required
                class="form-control"
                id="message"
                rows="5"
                placeholder="Your Message"
              ></textarea>
            </div>
            <div class="col-12">
              <button class="btn btn-primary" type="submit">Submit form</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Google Map Section -->
    <dev id="contact_3" class="contact_3 bg-white">
      <div class="container-flex">
        <div class="text-center pb-5">
          <h1 class="text-uppercase section_title">
            <!-- <strong>If more, please contact us</strong> -->
          </h1>
        </div>
        <div class="row">
          <div class="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.375673443496!2d90.36150337593631!3d23.80523668666785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b5575b54d319%3A0x47ebb31645b52700!2z4KaF4Kaf4KeL4Kar4Ka-4KaHIOCmuOCmsuCmv-CmieCmtuCmqOCmuCB8IEF1dG9meSBTb2x1dGlvbnM!5e0!3m2!1sen!2sbd!4v1730785965325!5m2!1sen!2sbd"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </dev>
  </section>
</template>

<script>
import metaMixin from "@/mixins/metaMixin";
import PageTitle from "./PageTitle.vue";

export default {
  name: "ContactUs",
  props: ["contentType"],
  mixins: [metaMixin],

  components: {
    PageTitle,
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        message: "",
        feedback_type_id: "",
        send_copy_to_yourself: false,
        ip: "",
      },
      isSubmitted: false,
      contactContent: {},
    };
  },
  computed: {
    contactTitle() {
      return this.contactContent["meta-title"] || "Contacts";
    },
    contactKeywords() {
      return this.contactContent["meta-keywords"] || "";
    },
    contactDescription() {
      return this.contactContent["meta-description"] || "";
    },
  },

  methods: {
    async fetchContactInfo() {
      try {
        const response = await fetch(`${this.$apijsonPath}`);
        const data = await response.json();
        this.contactContent =
          data?.sid_site?.app_setting?.organization_information;
        this.setMetaTags(
          this.contactTitle,
          this.contactKeywords,
          this.contactDescription
        );
        console.log("contact", data.sid_site);
      } catch (error) {
        console.error("Error fetching contact content:", error);
      }
    },

    async handleSubmit() {
      // Handle form submission logic
      const formData = new FormData();
      formData.append("first_name", this.form.first_name);
      formData.append("last_name", this.form.last_name);
      formData.append("email", this.form.email);
      formData.append("phone", this.form.phone);
      formData.append("message", this.form.message);
      formData.append("feedback_type_id", this.form.feedback_type_id); // Adding the feedback type ID
      formData.append("send_copy_to_yourself", this.form.send_copy_to_yourself);
      formData.append("ip", this.form.ip);

      const apiUrl =
        this.$apiBaseUrl +
        "website/website_api/feedback?access_key=" +
        this.$apiAccessKey;

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();

        if (response.ok) {
          // Clear the form inputs after successful submission
          this.isSubmitted = true;
          this.resetForm();
        } else {
          console.error(result);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    },
    // Method to reset form fields
    resetForm() {
      this.form = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        message: "",
        feedback_type_id: "",
        send_copy_to_yourself: false,
        ip: "",
      };
    },
  },
  mounted() {
    this.fetchContactInfo();
  },
};
</script>
