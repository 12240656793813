<template>
  <!--branding_team-->
  <section class="branding_team py-5">
    <div class="container">
      <div class="text-center">
        <div class="section_title text-white mb-3">
          Get Your On-Demand Branding Team
        </div>
        <div class="branding_section_text">
          <p v-html="blockData.description"></p>
        </div>
        <a href="/contact-us">
          <button class="all_btn px-5 mt-2">GET STARTED</button>
        </a>
      </div>
    </div>
  </section>
  <!--branding_team End-->
</template>

<script>
export default {
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
