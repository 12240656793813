<template>
  <div class="col-lg-4 mb-2">
    <!-- wc_sidebar Section -->
    <div>
      <div class="wc_sidebar">
        <!-- Popular Posts -->
        <div class="wc_widget">
          <h5 class="wc_widget-title">Recent {{ contentType }}</h5>

          <!-- Card for each post -->
          <div
            v-for="post in popularPosts.slice(0, 3)"
            :key="post.title"
            class="wc_sidebar_item d-flex mb-4"
          >
            <!-- Thumbnail Image -->
            <img
              :src="post.image_url"
              class="wc_sidebar_thumb me-3"
              alt="popular post"
            />
            <!-- Post Info -->
            <div style="flex: 1; padding-right: 10px">
              <router-link
                :to="{
                  name: 'SinglePage',
                  params: { alias: post.alias, contentType: contentType },
                }"
              >
                <p class="wc_sidebar-post-title">
                  {{ truncateTitle(post.title, 50) }}
                </p>
              </router-link>

              <p class="wc_sidebar-meta text-white" style="font-size: 0.9em">
                By Admin
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="add-wc_widget padding-bottom-30 mt-4 position-relative">
      <!-- White overlay -->
      <div
        class="overlay position-absolute top-0 start-0 w-100 h-100 bg-white opacity-10"
      ></div>

      <!-- Text at the top of the image -->
      <h2
        class="section_title sb_t position-absolute top-0 start-0 w-100 text-center text-white py-4"
      >
        Grab Your Whole Packages
      </h2>

      <!-- <div>
        <a class="all_btne" href="/about-us">Click Here</a>
      </div> -->

      <!-- Image -->
      <img
        class="img-fluid rounded-4"
        src="../assets/image/branding_offer_1.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["popularPosts", "contentType"],
  methods: {
    truncateTitle(title, limit) {
      return title.length > limit ? title.substring(0, limit) + "..." : title;
    },
  },
};
</script>
