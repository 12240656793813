<template>
  <!--service-->
  <!-- {{ blockData }} -->
  <section class="service py-5">
    <div class="container">
      <div class="text-center">
        <div class="section_title">{{ blockData.title }}</div>
        <div class="section_text">
          Whether you need new Website, Social Media Graphics<br />
          or Full Brand Creation, we do it all.
        </div>
      </div>
<!-- {{ blockData.items }} -->
      <div class="row pt-4">
        <!--service card-->
        <div
          v-for="(service, index) in blockData.items"
          :key="index"
          class="col-md-4 col-sm-12 my-2"
        >
          <div class="service_content shadow p-3 text-center">
            <img :src="service.image_url" alt="">
            <div class="service_title m-3">{{service.title}}</div>
            <div class="service_text">
              <p v-html="service.fulltext"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--service end-->
</template>

<script>
export default {
  name: "ServiceSection",
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
