<template>
  <!--Testimonial-->
  <section class="testimonial py-5">
    <div class="container">
      <div class="text-center">
        <div class="section_title">{{ blockData.title }}</div>
      </div>
      <swiper
        :slidesPerView="slidesPerView"
        :spaceBetween="20"
        :loop="true"
        :centeredSlides="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :pagination="false"
        :navigation="false"
        :modules="modules"
        class="mySwiper"
        :breakpoints="breakpoints"
      >
        <swiper-slide v-for="(slide, index) in blockData.items" :key="index">
          <div class="d-flex icon">
            <font-awesome-icon
              v-for="n in 4"
              :key="n"
              icon="star"
              class="me-1"
            />
          </div>
          <div class="row">
            <div class="testimonials_text pt-3">
              <p v-html="slide.fulltext"></p>
            </div>
            <div class="author d-flex">
              <div class="testimonial_img">
                <img class="img-fluid" :src="slide.image_url" alt="alt" />
              </div>
              <div class="mt-5">
                <div class="">{{ slide.name }}</div>
                <div class="">{{ slide["sub_title"] }}</div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
  <!--testimonial end-->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      breakpoints: {
        // Define breakpoints for responsive design
        640: {
          // For mobile devices
          slidesPerView: 1, // Show 1 slide per view on mobile
        },
        768: {
          // For tablets
          slidesPerView: 1, // Show 2 slides per view on tablet
        },
        1024: {
          // For larger devices
          slidesPerView: 1, // Show 3 slides per view on desktop
        },
      },
    };
  },

  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
