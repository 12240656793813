<template>
    <!--how it works-->
<section class="how_it_works py-5">
    <div class="container">
        <div class="text-center">
            <div class="section_title">{{blockData.title}}</div>
            <div class="section_text">Working with Branding Expert is easy and hassle-free  <br>from Start to Finish.</div>
        </div>
        <div class="row mt-5">
            <div v-for="(work,index) in blockData.items" :key="index"  class="col-md-3 col-sm-12 mb-3">
                <div class="how_content ">
                    <div class="how_number">
                        0{{ work.order }}
                    </div>
                    <div class="how_title">{{work.title}}</div>
                    <div class="how_text">
                        <p v-html="work.fulltext">
                        </p>
                    </div>
                    <div>
                        <a class="btn work_btn" href="/contact-us">GET STARTED</a>
                    </div>

                </div>
            </div>
         

        </div>
    </div>
</section>
<!--how it works end-->
</template>

<script>
export default{
    name:"WorkSection",
    props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
}
</script>