<template>
  <section class="menu_area sticky-top">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <!-- Logo -->
        <a class="navbar-brand logo" href="/">
          <img src="../../assets/image/logo.png" alt="Logo" />
        </a>

        <!-- Mobile Menu Toggle -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <!-- Menu Items -->
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li
              v-for="item in navItems"
              :key="item.id"
              class="nav-item"
              :class="{ dropdown: item.children && item.children.length }"
            >
              <!-- Dropdown Menu -->
              <template v-if="item.children && item.children.length">
                <a
                  class="nav-link dropdown-toggle text-white"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ item.label }}
                </a>
                <ul class="dropdown-menu">
                  <li v-for="child in item.children" :key="child.id">
                    <router-link class="dropdown-item" :to="`/${child.link}`">
                      {{ child.label }}
                    </router-link>
                  </li>
                </ul>
              </template>

              <!-- Single Menu Item -->
              <template v-else>
                <router-link class="nav-link text-white" :to="`/${item.link}`">
                  {{ item.label }}
                </router-link>
              </template>
            </li>
          </ul>

          <!-- Action Button -->
          <span class="navbar-text mt-3">
            <ul class="list-unstyled d-flex menu_buttons">
              <li>
                <a class="btn all_btn start_btn font-weight-bold" href="/contact-us">
                  START NOW
                </a>
              </li>
            </ul>
          </span>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import { useAppStore } from "@/stores/appStore"; // Import the Pinia store

export default {
  computed: {
    navItems() {
      const appStore = useAppStore();
      return appStore.navItems; // Directly access navItems from the store
    },
  },
};
</script>
