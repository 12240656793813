// src/mixins/metaMixin.js
export default {
    methods: {
      setMetaTags(title, keywords, description) {
        document.title = title || "Default Title";
        this.updateMetaTag("keywords", keywords || "");
        this.updateMetaTag("description", description || "");
      },
      updateMetaTag(name, content) {
        let metaTag = document.querySelector(`meta[name="${name}"]`);
        if (!metaTag) {
          metaTag = document.createElement("meta");
          metaTag.setAttribute("name", name);
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute("content", content);
      },
    },
  };
  