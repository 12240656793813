<template>
  <!-- Portfolio Section -->
  <section class="portfolio py-5 container" id="portfolio">
    <div class="text-center">
      <div class="section_title">Portfolio</div>
      <div class="section_text">
        Pick between Bronze, Gold, and Platinum level<br />
        Packages based on your needs
      </div>
    </div>

    <!-- Tab Navigation -->
    <nav class="pt-4 nav_list">
      <div
        class="nav nav-pills justify-content-center price_tabs"
        role="tablist"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['nav-link', { active: activeTab === tab.id }]"
          @click="activeTab = tab.id"
          type="button"
          role="tab"
          :aria-controls="`nav-${tab.id}`"
          :aria-selected="activeTab === tab.id"
        >
          {{ tab.label }}
        </button>
      </div>
    </nav>

    <!-- Tab Content -->
    <div class="tab-content pt-4">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :id="`nav-${tab.id}`"
        class="tab-pane fade"
        :class="{ 'show active': activeTab === tab.id }"
        role="tabpanel"
        :aria-labelledby="`nav-${tab.id}-tab`"
        tabindex="0"
      >
        <!-- Images for each Tab -->
        <div class="row">
          <div
            class="col-lg-4 col-md-6 my-2 shadow-inset-center"
            v-for="(image, imgIndex) in tab.images"
            :key="imgIndex"
          >
            <div class="image_container">
              <!-- Display Image -->
              <img
                class="img-fluid"
                :src="image"
                alt="Package Image"
                @error="onImageError($event)"
              />
              <!-- Eye icon overlay -->
              <div class="eye_icon">
                <i class="fas fa-eye"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center pt-3">
      <a href="/portfolio" class="all_btn">All Portfolio</a>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "logo",
      tabs: [
        {
          id: "logo",
          label: "Logo",
          images: [
            "https://autofybranding.com/fdrives/sid/autofybranding/component6_s53156.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component7_s06914.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component8_s95986.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component3_s89122.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component5_s16904.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component4_s75651.png",
          ],
        },
        {
          id: "business",
          label: "Business Card",
          images: [
            "https://autofybranding.com/fdrives/sid/autofybranding/component6_s53156.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component5_s16904.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component3_s89122.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component4_s75651.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component7_s06914.png",
            "https://autofybranding.com/fdrives/sid/autofybranding/component8_s95986.png",
          ],
        },
      ],
    };
  },
  methods: {
    /**
     * Handle image loading errors.
     */
    onImageError(event) {
      console.error("Image failed to load:", event.target.src);
      event.target.src =
        "https://via.placeholder.com/150/FFFFFF/000000?text=Image+Not+Found"; // Fallback image
    },
  },
};
</script>