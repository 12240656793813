<template>
  <!-- Blog -->
  <section class="blog">
    <div class="blog_list container py-5">
      <div class="text-center">
        <p class="section_title">Blog</p>
        <p class="section_text">
          Whether you need a new Website, Social Media Graphics, or Full Brand
          Creation, we do it all.
        </p>
      </div>
      <div class="pt-4 row">
        <div
          v-for="(blog, index) in blockData.items"
          :key="index"
          class="col-md-4 col-sm-12"
        >
          <div>
            <img class="img-fluid" :src="blog.image_url" alt="Blog Image" />

            <div class="blog_title mt-2 mb-2">
              <a :href="blog.url">{{ blog.title }}</a>
            </div>
            <div class="blog_text">
              <p v-html="truncateText(blog.fulltext, 100)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <a class="all_btn" href="/blog">All Blog</a>
    </div>
  </section>
  <!-- Blog End -->
</template>

<script>
export default {
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    truncateText(text, length) {
      // Check if text is defined and is a string
      if (text && typeof text === "string") {
        return text.length > length ? `${text.slice(0, length)}....` : text;
      }
      // Return an empty string or a default message if text is undefined or not a string
      return "";
    },
  },
};
</script>
