<template>
  <div>
    <div v-if="appStore.loading">Loading...</div>
    <div v-else>
      <BannerSection />
      <component
        v-for="block in homePageArray"
        :key="block.widget_element_path"
        :is="resolveComponent(block.widget_element_path)"
        :block-data="block"
      />
    </div>
  </div>
</template>

<script>
import BannerSection from "./BannerSection.vue";
import AboutSection from "./AboutSection.vue";
import ServiceSection from "./ServiceSection.vue";
import WorkSection from "./WorkSection.vue";
import PriceSection from "./PriceSection.vue";
import BrandingSection from "./BrandingSection.vue";
import TestimonialSection from "./TestimonialSection.vue";
import BlogSection from "./BlogSection.vue";
import PortfolioSection from "./PortfolioSection.vue";
import { useAppStore } from "@/stores/appStore";

export default {
  name: "HomePage",
  components: {
    BannerSection,
    AboutSection,
    ServiceSection,
    WorkSection,
    PriceSection,
    BrandingSection,
    TestimonialSection,
    BlogSection,
    PortfolioSection,
  },
  setup() {
    const appStore = useAppStore();
    const resolveComponent = (widgetPath) => {
      const componentMap = {
        BannerSection,
        AboutSection,
        ServiceSection,
        WorkSection,
        PriceSection,
        BrandingSection,
        TestimonialSection,
        BlogSection,
        PortfolioSection,
      };
      return componentMap[widgetPath] || null;
    };

    return {
      appStore,
      resolveComponent,
    };
  },
  computed: {
    homePageArray() {
      return Object.values(this.appStore.homePage || {});
    },
  },
};
</script>
